<!-- TODO: move the file, rewrite class-based -->
<template>
  <div class="promo-page">
    <div class="promo-page-content">
      <edit />
    </div>
    <confirm-unsaved-leave />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import edit from '@/_modules/promo/components/edit/edit.vue';
import ConfirmUnsavedLeave from "@/views/components/popups/confirm-unsaved-leave";

export default {
  name: 'promo-page-edit',
  components: {
    ConfirmUnsavedLeave,
    edit,
  },
  computed: {
    ...mapGetters('editFormStore', {
      hasUnsavedChanges: 'hasUnsavedChanges',
      isConfirmLeavePopupShown: 'isConfirmLeavePopupShown',
    }),
  },
  data() {
    return {
    };
  },
  beforeDestroy() {
    this.setHasUnsavedChanges(false);
    this.hideConfirmLeavePopup();
  },
  methods: {
    ...mapActions('editFormStore', {
      showConfirmLeavePopup: 'showConfirmLeavePopup',
      hideConfirmLeavePopup: 'hideConfirmLeavePopup',
      setHasUnsavedChanges: 'setHasUnsavedChanges',
      leave: 'leave'
    }),
  },
  beforeRouteLeave (to, from, next) {
    // Checks for unsaved changes in the form
    if (this.hasUnsavedChanges) {
      this.showConfirmLeavePopup(next);
    } else {
      this.setHasUnsavedChanges(false);
      this.hideConfirmLeavePopup();
      next();
    }
  }
};
</script>

<style scoped lang="scss">
</style>
