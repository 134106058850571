<template src="./edit-contact.html"></template>
<style src="./edit-contact.scss" scoped lang="scss"></style>
<style src="./edit-contact-non-scoped.scss" lang="scss"></style>
<script>
// TODO: rewrite using class-based @Component syntax
import { mapState, mapActions, mapGetters } from 'vuex';
import { email, maxLength, required, url } from 'vuelidate/lib/validators';

import uploadImage from '@/views/components/uploadImages/uploadImage.vue';
import uploadFiles from '@/views/components/uploadFiles/uploadFiles.vue';
import Vue from 'vue';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import IconCloseQrPopup from '@/_modules/icons/components/qrpopup/icon-close-qr-popup.vue';

const CONTACT_TAG_SELECTION_LIMIT = 10;

const editContact = Vue.extend({
  name: 'edit-contact',
  components: {
    uploadImage,
    uploadFiles,
    IconCloseQrPopup,
  },
  computed: {
    ...mapState('locationStore', ['countryList', 'cityList', 'loading']),
    ...mapState('promoStore', ['editPromoPageLoading', 'editPromoPageSuccess', 'promoOwnerId', 'productsListStandalone']),
    ...mapState({
      promoPage: state => state.promoStore.myPromoPage,
      eventInfo: state => state.eventStore.eventInfo,
    }),
    ...mapGetters({
      userInfo: '_userStore/user',
      hasUnsavedChanges: 'editFormStore/hasUnsavedChanges',
      contactInfo: 'promoPageStore/contact',
      isContactLoading: 'promoPageStore/isContactLoading',
      event: '_eventStore/event',
      eventId: '_eventStore/eventId',
      eventContactTags: '_eventStore/eventContactTags',
    }),
    contactCompanyWebsite: {
      get: function () {
        return this.contact.company_website || 'https://';
      },
      set: function (url) {
        this.contact.company_website = url === 'https://' ? '' : url;
      },
    },
    isContactTagSelectionLimitReached: {
      get: function () {
        const selectedTagsQuantity = this.eventContactTagsLocal.filter((item) => item.isSelected).length;
        return CONTACT_TAG_SELECTION_LIMIT <= selectedTagsQuantity;
      },
    },
    eventContactTagsLocalSelected: {
      get: function () {
        // Not cloning because we do not mutate it in any way yet. Feel free to _cloneDeep if requirements change.
        return this.eventContactTagsLocal.filter((item) => item.isSelected);
      },
    },

    isLanguageSavingNeeded() {
      // Return true when we have the language list, it is not empty and the current edit language is set
      return !!(this.eventLanguages && (this.eventLanguages.length > 0) && this.currentEditLanguage);
    },

    currentEditLanguage() {
      if (this.eventLanguages && this.eventLanguages.length > 0) {
        if (this.selectedLanguageTab) {
          return this.selectedLanguageTab;
        }
        return this.eventLanguages[0];
      }

      // TODO: is it ok? Maybe not.
      // When a user visits by URL, say, ru/events/365/promo/edit/company
      // they see the promopage data in Russian, but the selected tab is English
      // This can save bad data into the DB.
      // Because of this, I hardcoded a default language here so that
      // this.getPromopageContactId always has some language in this.currentEditLanguage
      return 'en';
    },
  },
  props: {
    eventLanguages: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    userInfo: {
      deep: true,
      immediate: true,
      handler() {
        this.callContactInfo();
      }
    },
    contactInfo: {
      deep: true,
      handler(newVal) {
        if (!newVal) {
          return;
        }
        this.setContactData();
        if (this.$refs.userPhotoUploader && this.contactInfo.photo_url) {
          this.$refs.userPhotoUploader.showExternalImage(this.contactInfo.photo_url);
        }
      }
    },
    contact: {
      deep: true,
      handler() {
        this.setHasUnsavedChanges(!_isEqual(this.contact, this.oldContact));
      }
    },
    eventContactTags: {
      handler() {
        let selectedTagIds = [];
        if (this.contact && this.contact.tags) {
          selectedTagIds = this.contact.tags.map((item) => item.id);
        }
        this.eventContactTagsLocal = _cloneDeep(this.eventContactTags).map((item) => {
          item.isSelected = false;
          if (selectedTagIds.find(selectedItem => item.id === selectedItem)) {
            item.isSelected = true;
          }
          return item;
        });
      }
    },
    isContactLoading: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.isContactLoadingWatcherBlocked) {
          return;
        }
        if (!newVal && oldVal) {
          this.refreshContactData();
          this.isContactLoadingWatcherBlocked = true;
        }
      }
    }
  },
  mounted() {
    this.contact.event_id = this.$route.params.eventId; // TODO: do we need this?
    this.$store.dispatch('locationStore/countryList');
    // TODO: refactor. maybe research the getContactPromoPageRequest.cancel() way — check side-bar-right, bad effects of cancel() can be there.
    if (this.isContactLoading) { // AW-2671. Could also be done by getContactPromoPageRequest.cancel() but that creates other problems.
      this.isContactLoadingWatcherBlocked = false;
    } else {
      this.refreshContactData();
    }
    this.getContactTags();
  },
  data() {
    return {
      errors: {
        website: ''
      },
      isLoading: false,
      isContactLoadingWatcherBlocked: true,
      selectedLanguageTab: '',
      isEditSuccessMessageShown: false,
      contactId: -1,
      contact: {
        id: 0,
        event_id: '0',
        name: '',
        surname: '',
        email: '',
        photo_url: '',
        country: '',
        city: '',
        company_name: '',
        company_website: '',
        company_position: '',
        company_description: '',
        files: [],
        tags: [],
        phone: '',
      },
      oldContact: { // Needed to compare with in watch/contact
        id: 0,
        event_id: '0',
        name: '',
        surname: '',
        email: '',
        photo_url: '',
        country: '',
        city: '',
        company_name: '',
        company_website: '',
        company_position: '',
        company_description: '',
        files: [],
        tags: [],
        phone: '',
      },
      isContactTagSelectorVisible: false,
      eventContactTagsLocal: [],
    };
  },
  methods: {

    ...mapActions('editFormStore', {
      setHasUnsavedChanges: 'setHasUnsavedChanges'
    }),

    ...mapActions('promoPageStore', {
      editContact: 'editContact'
    }),

    ...mapActions('editFormStore', {
      showConfirmLeavePopup: 'showConfirmLeavePopup',
      hideConfirmLeavePopup: 'hideConfirmLeavePopup',
      setHasUnsavedChanges: 'setHasUnsavedChanges',
      leave: 'leave'
    }),

    async refreshContactData() {
      await this.callContactInfo();
      this.setContactData();
    },

    async callContactInfo() {
      await this.$store.dispatch('promoPageStore/getContact', {
        eventId: this.eventId,
        acceptLanguage: this.currentEditLanguage
      });
    },

    setContactData() {

      if (!this.contactInfo) return;

      this.contactId = this.contactInfo.id;

      // contact data
      for (let key in this.contact) {
        if (this.contactInfo[key]) {
          this.contact[key] = _cloneDeep(this.contactInfo[key]);
        } else if(typeof this.contact[key] == 'string') {
          this.contact[key] = ''
        }
      }

      this.oldContact = _cloneDeep(this.contact);

    },

    handlePhotoUrl(data) {
      this.contact.photo_url = data.image;
      this.$store.dispatch('promoPageStore/setContactPhotoURL', data.image);
    },

    handleFiles(data) {
      this.contact.files = data;
    },

    phoneInputKeyupHandler() {
      this.contact.phone = '+' + this.contact.phone.replace(/[^\d]/g, '');
      if (this.contact.phone === '+') {
        this.contact.phone = '';
      }
    },

    async submitContactForm() {

      this.removeEditContactSuccessMessage();

      this.$v.contact.$touch();
      if (this.$v.contact.$pending || this.$v.contact.$invalid) {
        /* vuelidation error(s) */
        return;
      }

      this.isLoading = true;

      const contactDataToSend = _cloneDeep(this.contact);
      delete contactDataToSend.files; // Files have to be sent separately since they are an array of objects
      delete contactDataToSend.id; // API PATCH contact/info does not accept the id request parameter
      contactDataToSend.tags = this.eventContactTagsLocalSelected;

      if (this.isLanguageSavingNeeded) {
        contactDataToSend.lang = this.currentEditLanguage;
      }
      contactDataToSend.lang = this.currentEditLanguage;
      const editActionStatus = await this.$store.dispatch('promoPageStore/editContact', contactDataToSend);
      if (editActionStatus) {
        this.showEditContactSuccessMessage();
      }

      // TODO: check can we remove this call and rely only on Vuex reactivity?
      await this.$store.dispatch('promoPageStore/getContact', { eventId: this.eventId, acceptLanguage: this.currentEditLanguage });

      // Sending contact files (documents)
      for (let i = 0; i < this.contact.files.length; i++) {

        // If the file has id or tempUploadedFlag, then it has already been sent, no need to re-send.
        if (this.contact.files[i].id || this.contact.files[i].tempUploadedFlag || !this.contact.files[i].url) {
          continue;
        }

        const fileInfoToSend = {
          url: this.contact.files[i].url,
          filename: this.contact.files[i].name,
          event_id: this.eventId
        };

        this.$store.dispatch('contactsStore/putContactFile', fileInfoToSend).then(() => {
          // Marking local file info object as sent with the «tempUploadedFlag»
          this.contact.files.splice(i, 1, {
            ...this.contact.files[i],
            tempUploadedFlag: true
          });
        });
      }

      this.isLoading = false;

      // The unsaved must have been saved at this point.
      this.setContactFormSaved();
    },

    /* Marks the editFormStore flag as false
     * Clones local contact into oldContact for future checks
     */
    setContactFormSaved() {
      this.oldContact = _cloneDeep(this.contact);
      this.setHasUnsavedChanges(false);
    },

    getPromoPageByContactId() {
      const payload = {
        event_id: this.$route.params.eventId,
        contact_id: this.contactId,
      };

      if (this.currentEditLanguage) {
        payload.acceptLanguage = this.currentEditLanguage;
      }

      this.$store.dispatch('promoStore/getMyPromoPageContactId', payload);
    },

    showEditContactSuccessMessage() {
      this.isEditSuccessMessageShown = true;
      setTimeout(this.removeEditContactSuccessMessage, 9000);
    },

    removeEditContactSuccessMessage() {
      this.isEditSuccessMessageShown = false;
    },

    openContactTagsSelectorPopup() {
      this.isContactTagSelectorVisible = true;
    },

    closeContactTagsSelectorPopup() {
      this.isContactTagSelectorVisible = false;
    },

    handleContactTagClick(contactTag) {
      if (contactTag.isSelected === false && this.isContactTagSelectionLimitReached) {
        return;
      }
      contactTag.isSelected = !contactTag.isSelected;
    },

    async getContactTags() {
      await this.$store.dispatch('_eventStore/getEventContactTags');
    },

    clearSelectedContactTags() {
      this.eventContactTagsLocal = this.eventContactTagsLocal.map((item) => {
        item.isSelected = false;
        return { ...item };
      });
      this.closeContactTagsSelectorPopup();
    },
    checkUnsavedChanges() {
      const result = (
        _isEqual(this.companyEdit, this.oldCompanyEdit)
        && (!this.hasCompanyWebsiteUrlChanged())
        && _isEqual(this.promoPageFiles, this.promoPage.files)
        && _isEqual(this.promoPagePhotos, this.promoPage.photos)
        && this.areLocalTagsSimilarToSaved()
        && _isEqual(this.promoPageProducts, this.promoPage.products));

      this.setHasUnsavedChanges(!result);
    },

    selectLanguageTab(langCode) {
      const leaveAction = () => {
        if (this.selectedLanguageTab !== langCode) {
          this.selectedLanguageTab = langCode;
          this.setHasUnsavedChanges(false);
          this.hideConfirmLeavePopup();
          this.$store.dispatch('promoPageStore/getContact', { eventId: this.eventId, acceptLanguage: this.currentEditLanguage });
        } else {
          this.selectedLanguageTab = langCode;
        }
      }

      if (this.selectedLanguageTab !== langCode && this.hasUnsavedChanges) {
        this.showConfirmLeavePopup(leaveAction);
      } else {
        leaveAction();
      }

    },
    setDefaultTranslate(){
      this.$store.dispatch('promoPageStore/getContact', { eventId: this.eventId, acceptLanguage: 'en' });
      this.setContactData();
    }
  },
  validations: {

    contact: {
      name: {
        required,
      },
      surname: {
        required,
      },
      email: {
        maxLength: maxLength(250),
        email,
        required,
      },
      company_website: {
        maxLength: maxLength(250),
        url,
      },
      video_stream_url: {
        maxLength: maxLength(250),
        url,
      },
      company_name: {
        required,
      },
      company_position: {
        required,
      }
    },
  },

});
export default editContact;
</script>
