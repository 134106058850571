


import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import EventHelper from '@/_helpers/event.helper';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TContact } from '@/_types/contact.type';
import { TEvent } from '@/_types/event.type';
import editContact from '@/views/components/promoPage/edit-contact/edit-contact.vue';
import EditCompany from '@/_modules/promo-cabinet/components/edit-company/edit-company.vue';

@Component({
  components: {
    editContact,
    EditCompany,
  }
})
export default class Edit extends Vue {

  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('promoPageStore/isLoading') contactInfoRequested: boolean;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoPageStore/contact') contact: TContact;
  @Getter('promoPageStore/promoPage') promoPage: TPromoPage;
  @Getter('promoPageStore/isPromoPageLoading') isPromoPageLoading: boolean;
  @Getter('editFormStore/hasUnsavedChanges') hasUnsavedChanges: boolean;

  @Action('editFormStore/showConfirmLeavePopup') showConfirmLeavePopup: (leave?: () => void) => void;
  @Action('editFormStore/hideConfirmLeavePopup') hideConfirmLeavePopup: () => void;
  @Action('editFormStore/setHasUnsavedChanges') setHasUnsavedChanges: (value: boolean) => void;

  public currentlyEditingEntity: string = '';

  public get isEditingContact(): boolean {
    return this.currentlyEditingEntity === 'contact';
  }

  public get isEditingCompany(): boolean {
    return this.currentlyEditingEntity === 'company';
  }

  public get eventEditors(): TContact[] {
    if (!this.event || !this.event.editors) {
      return [];
    }

    return this.event.editors;
  }

  public get isEventOrganizer(): boolean {
    return EventHelper.isContactEventOrganizer(this.event, this.contact);
  }

  public get isEventEditor(): boolean {
    if (!this.contact) {
      return false;
    }
    return !this.isEventOrganizer && !!this.eventEditors.find((editor: TContact) => editor.id === this.contact.id);
  }

  public get isAbleToAccessOrganizerCabinet(): boolean {
    return this.isEventOrganizer || this.isEventEditor;
  }

  /* Закомментировано в таске AW-1631. Переводы не удалялись.
  public get currentlyEditingEntityTitle(): TranslateResult {
    return this.$t('editContactAndCompany.titleEdit_' + this.currentlyEditingEntity);
  }
   */

  public get eventLanguages(): string[] {
    if (this.event && this.event.languages) {
      return this.event.languages;
    }
    return [];
  }

  /* Sets the name of the entity that is currently being edited
   * accounting for hasUnsavedChanges
   */
  private changeEditingEntity(entityName: string): void {

    const leaveAction = (): void => {
      this.setHasUnsavedChanges(false);
      this.hideConfirmLeavePopup();

      if (this.currentlyEditingEntity === entityName) {
        return;
      }

      this.currentlyEditingEntity = entityName;

      this.$router.push({
        name: 'promo-page-edit-' + this.currentlyEditingEntity
      });
    };

    if (this.hasUnsavedChanges) {
      this.showConfirmLeavePopup(leaveAction);
    } else {
      leaveAction();
    }

  }

  /* Returns the name of the component to be shown (standard so-called «dynamic components» practice) */
  private getEditFormComponentName(): string {

    switch (this.$route.name) {
      case 'promo-page-edit-contact':
        this.currentlyEditingEntity = 'contact';
        return 'edit-contact';

      case 'promo-page-edit-company':
        this.currentlyEditingEntity = 'company';
        return 'edit-company';

      default:
        this.currentlyEditingEntity = 'contact';
        return 'edit-contact';

    }

  }
}

