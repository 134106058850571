<template>
  <div class="upload-files">
    <div class="block-title">
      <slot></slot>
    </div>
    <div class="file-wrapper">
      <mu-paper class="file-block-paper" v-for="(file, index) in photosAndVideo" :key="index" :z-depth="0">
        <div class="file-block" v-if="photosAndVideo[index]">
          <div
              class="preview-blurred"
              v-if="isImage(file)"
              :style="{ backgroundImage: 'url(' + file.url + ')' }"
          ></div>
          <div class="preview-blurred" v-else></div>
          <div class="preview-dimmer"></div>
          <div
              class="preview-contain"
              v-if="isImage(file)"
              :style="{ backgroundImage: 'url(' + file.url + ')' }"
          >
          </div>
          <div class="preview-contain"
               v-else>
            <div class="file-icon">
              <div v-if="file.extension" class="file-extension">{{ file.extension }}</div>
            </div>
          </div>
          <div class="actions">
            <div class="action delete"
                 @click="removeFiles(index, file.id)">
              <IconSquareDelete/>
            </div>
          </div>
          <div class="file-meta">
            <div class="filename"
                 v-if="photosAndVideo[index].name || photosAndVideo[index].filename"
                 :class="{'filename-long' : photosAndVideo[index].nameClean.length > 15}"
            >{{ photosAndVideo[index].nameClean }}
            </div>
            <div class="fileext"
                 v-if="photosAndVideo[index].extension">
              <span v-if="photosAndVideo[index].extension">{{ photosAndVideo[index].extension }}</span>
            </div>
          </div>
        </div>
      </mu-paper>
      <div class="file-block-paper" v-if="loading">
        <div class="file-block file-block-progress">
          <div class="sizer"></div>
          <div class="preview-contain">
            <mu-circular-progress/>
          </div>
        </div>
      </div>
      <div class="file-block-paper" v-else>
        <div class="file-block file-block-upload">
          <div class="sizer"></div>
          <div class="preview-contain">
            <div class="uploader-icon">
              <icon-plus />
            </div>
            <input class="upload-input" type="file" @change="onFileChange" title=""/>
          </div>
        </div>
      </div>
    </div>
    <span v-if="errors.photosAndVideo" class="error">{{$t(errors.photosAndVideo)}}</span>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconPlus from '@/_modules/icons/components/icon-plus.vue';

//TODO: remove file after rewrite editUserInfoPage

export default {
  name: 'upload-photos-and-video',
  components:{
    IconSquareDelete,
    IconPlus,
  },
  computed: {
    ...mapState('uploadPromopageFilesStore', ['file', 'loading', 'uploadedPhotoVideo']),
    ...mapState({
      photoVideoError: state => state.uploadPromopageFilesStore.photoVideoError,
    })
  },
  props: {
    photosAndVideo: {
      type: Array,
      default: function () {
        return [];
      }
    },
    deleteRoute: {
      type: String,
      default: 'promoPagePhotos'
    },
    externalId: {
      type: String,
      default: '-1'
    },
    imageTargetWidth: {
      type: Number,
      default: -1
    },
    imageTargetHeight: {
      type: Number,
      default: -1
    },
    imageTargetMode: {
      type: String,
      default: ''
    },
  },
  watch: {
    photosAndVideo: {
      deep: true,
      immediate: true,
      handler() {
        // create nameClean, fileext for design
        for (let i = 0; i < this.photosAndVideo.length; i++) {
          let file = this.photosAndVideo[i];
          if (!file.filename) {
            continue;
          }
          let fileExt = (file.url.indexOf('.') > -1 ? file.url.split('.').pop() : '');
          let nameClean = (fileExt ? file.filename.replace('.' + fileExt, '') : file.filename);
          if (typeof file.nameClean == 'undefined') {
            this.photosAndVideo[i].nameClean = nameClean;
          }
          if (fileExt) {
            this.photosAndVideo[i].extension = fileExt;
          }
        }
        this.formData.photosAndVideo = this.photosAndVideo;
      }
    },
    photoVideoError() {
      if (this.photoVideoError.code) {
        this.setError({message_key: 'errors.validation.file_upload_failed'},);
      } else {
        this.rmErr();
      }
    },
  },
  data: () => ({
    placeholder: null,
    fileIcon: 'add_circle_outline',
    filesSize: '',
    filesType: null,
    list: [],
    count: 12,
    errors: {
      photosAndVideo: '',
      uploadImageError: '',
    },
    formData: {
      photosAndVideo: []
    }
  }),
  mounted() {
    if (this.photosAndVideo) {
      this.formData.photosAndVideo = this.photosAndVideo;
    }
  },
  methods: {
    isImage(file) {
      if (!file) return false;
      let url = file.url ? file.url : (file.name ? file.name : '');
      let whiteList = {
        jpg: true,
        png: true,
        gif: true,
        jpe: true,
        jpeg: true,
        svg: true
      };

      if (url) {
        url = url.split('.');
        const ext = url.pop().toLowerCase();
        return Object.prototype.hasOwnProperty.call(whiteList, ext);
      }

      return false;

    },
    isImageAndNotSVG(file) {
      if (!file) return false;
      let url = file.url ? file.url : (file.name ? file.name : '');
      let whiteList = {
        jpg: true,
        png: true,
        gif: true,
        jpe: true,
        jpeg: true,
      };

      if (url) {
        url = url.split('.');
        const ext = url.pop().toLowerCase();
        return Object.prototype.hasOwnProperty.call(whiteList, ext);
      }

      return false;

    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      this.createFile(files[0]);
    },
    createFile(file) {
      let reader = new FileReader();

      reader.onload = async () => {
        this.filesType = file.type;
        this.returnFileSize(file.size, false);

        file = this.addTargetDimensions(file);

        if (this.validateFiles()) {
          this.$store.dispatch('uploadPromopageFilesStore/uploadPromoPagePhotoVideo', file).then(() => {
            let fileExt = file.name.indexOf('.') > -1 ? file.name.split('.').pop() : '';
            // Backend has time limit of N sec, after which it closes the connection
            if(!this.uploadedPhotoVideo.url){
              this.setError({message_key: 'errors.validation.connection_timed_out'});
              return;
            }
            let fileObj = {
              url: this.uploadedPhotoVideo.url,
              name: file.name,
              nameClean: (fileExt ? file.name.replace('.' + fileExt, '') : file.name),
              extension: fileExt,
              size: this.uploadedPhotoVideo.size,
              type: this.uploadedPhotoVideo.type
            };
            this.formData.photosAndVideo.push(fileObj);
            this.$emit('photosAndVideoData', this.formData.photosAndVideo);
          });
        }

      };
      reader.readAsDataURL(file);

    },

    // Add dimension and mode params, if present in props
    addTargetDimensions(fileInfo) {

      if(!this.isImageAndNotSVG(fileInfo)) {
        return fileInfo;
      }

      if (this.imageTargetWidth > -1) {
        fileInfo.w = this.imageTargetWidth;
      }
      if (this.imageTargetHeight > -1) {
        fileInfo.h = this.imageTargetHeight;
      }
      if( this.imageTargetMode && (fileInfo.w && fileInfo.h) ) {
        fileInfo.mode = this.imageTargetMode;
      }

      return fileInfo;
    },

    returnFileSize(number) {

      let result;
      if (number < 1024) {
        result = number + 'bytes';
      } else if (number >= 1024 && number < 1048576) {
        result = (number / 1024).toFixed(1) + 'KB';
      } else if (number >= 1048576) {
        result = (number / 1048576).toFixed(1) + 'MB';
      }

      this.filesSize = {
        sizeText: result,
        rawSize: number
      };

    },
    removeFiles(index, fileId) {
      let actionName = 'promoStore/deletePhotoVideoUrlPromoPage';
      let payload = {data: {file_id: fileId}, event_id: this.$route.params.eventId, external_id: this.externalId};
      if (fileId) {
        this.$store.dispatch(actionName, payload);

      }
      if (!fileId) {
        this.$store.dispatch('uploadPromopageFilesStore/clearPromoPagePhotoVideoError');
      }
      this.formData.photosAndVideo.splice(index, 1);

      this.$emit('photosAndVideoData', this.formData.photosAndVideo);
    },
    validateFiles() {
      let isFile;
      if (this.filesType) {
        isFile = this.filesType.split('/');
      }

      if (this.filesSize && this.filesSize.rawSize >  300 * 1024 * 1024) { // approx 150 MB
        this.setError({message_key: 'errors.validation.file_size_too_large'});
        this.filesSize = ''
      } else if (!isFile || !isFile[0] || ((isFile[0] !== 'image') && (isFile[0] !== 'video'))) {
        this.setError({message_key: 'errors.validation.file_upload_failed'});
      } else if (
        (isFile[0] === 'image' && this.filesSize.rawSize > 10 * 1024 * 1024) ||
          (isFile[0] === 'video' && this.filesSize.rawSize > 300 * 1024 * 1024)
      ) {
        this.setError({message_key: 'errors.validation.file_size_too_large'});
      } else {
        this.rmErr();
        return true;
      }
    },
    setError(err) {
      this.errors.photosAndVideo = err.message_key;
    },
    rmErr() {
      this.errors.photosAndVideo = null;
      this.filesSize = ''
    },
  }
}

</script>
<style scoped lang="scss">
  .paper {
    padding: 20px 0;
    text-align: center;
  }

  .block {
    &-title {
      color: rgba(0, 0, 0, 0.38);
      margin-bottom: 1rem;
    }
  }

  .upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.001;
    z-index: 5;
    cursor: pointer;
  }

  .error {
    color: #dd4b39;
    text-align: left;
  }

  .button-wrapper {
    text-align: center;

    .mu-button {
      margin: 8px 8px 8px 0;
      vertical-align: middle;
    }

    a.mu-button {
      text-decoration: none;
    }
  }

  .file-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
  }

  .file-block-paper {
    border-radius: 0;
    overflow: visible;
  }

  .file-block {
    position: relative;
    width: 60px;
    margin: 0 60px 64px 0;
    border-radius: 7px;

    .preview-blurred,
    .sizer {
      padding-bottom: 100%;
      background-size: 300%;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      z-index: 1;
      border-radius: 7px;
      overflow: hidden;
    }

    .preview-dimmer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.25;
      border-radius: 7px;
      overflow: hidden;
    }

    .preview-contain {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 7px;
      overflow: hidden;
    }

    .actions {
      position: absolute;
      right: -31px;
      top: 0;
      height: 100%;
      display: flex;

      .action {
        margin-right: 1.6rem;
        justify-content: center;
        display: flex;
        flex-flow: column wrap;

        svg {
          width: 24px;
          height: 24px;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          opacity: 1;
        }

        &.edit {
          color: #57c7ff;
        }

        &.delete {
          color: #f77;
        }
      }
    }

    .file-meta {
      position: absolute;
      top: 100%;
      left: 0;
      padding-top: 16px;
      font-size: 1.2rem;
      cursor: pointer;
      width: 100%;

      .filename {
        position: absolute;
        top: 12px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 140%;
        overflow: hidden;
        font-size: 12px;
        text-align: center;
        white-space: nowrap;

        &-long {
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .fileext {
        opacity: 0.3;
        text-align: center;
      }

      .action {
        opacity: 0.8;
        margin-right: 1.6rem;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          opacity: 1;
        }

        &.edit {
          color: #57c7ff;
        }

        &.delete {
          color: #f77;
        }
      }
    }

    &-upload {
      background: rgba(0, 182, 248, 0.1);
      margin-right: 0;

      .mu-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }
    }

    &-progress {
      background-color: #f2f2f2;

      .mu-circular-progress {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .title {
      overflow: hidden;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }

  .file-block-paper:last-child .file-block {
    margin-right: 0;
  }

  .flex-container {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
  }

  .button {
    color: #fff;
    background-color: #3369ff;
  }

  .file-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 30px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 0 5px 0 0;
  }

  .file-icon::before,
  .file-icon::after {
    content: "";
    position: absolute;
    background-color: #ccc;
  }

  .file-icon::before {
    width: 1px;
    height: 5px;
    top: 0;
    right: 5px;
  }

  .file-icon::after {
    width: 5px;
    height: 1px;
    top: 5px;
    right: 0;
  }

  .file-icon .file-extension {
    background-color: #777;
    position: absolute;
    bottom: 5px;
    right: -5px;
    color: #fff;
    padding: 1px 2px;
    font-size: 9px;
    line-height: 1;
  }

  .uploader-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    transform: translate(-50%, -50%);

    /deep/ svg {
      width: 16px;
      height: 16px;
    }
  }
</style>
